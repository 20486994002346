import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '5511934108166'; // Substitua pelo seu número
    const message = 'Olá, acabei de solicitar uma cotação.'; // Mensagem personalizada
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = url;
  };

  return (
    <div className="whatsapp-button" onClick={handleWhatsAppClick} aria-label="Fale conosco no WhatsApp">            

      <img src="/whats.png" alt="WhatsApp" />
    </div>
  );
};

export default WhatsAppButton;