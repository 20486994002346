import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import WhatsAppButton from "./components/WhatsAppButton";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import SimularCredito from "./pages/SimularCredito";
import SolucoesFinanceiras from "./pages/SolucoesFinanceiras";
import Seguros from "./pages/CotacaoFacilPage.js";
import SeguroResidencial from "./pages/SegurResiencial.js";
import ParaMim from "./pages/ParaMim.js";
import ParaEmpresa from "./pages/ParaEmpresa";
import Contato from "./pages/Contato";
import PoliticaPrivacidade from "./pages/politica-de-privacidade";
import TermoDeUso from "./pages/TermoDeUso";
import ScrollToTop from "./components/ScrollToTop";
import Sobre from "./pages/Sobre";
import LimpaNome from "./pages/LimpaNome.js";
import BrevoConversations from "./components/BrevoConversations.js";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8rG8Jxu0OXDF334-MZxaqF4tT66Q-Q2c",
  authDomain: "capitus-daf71.firebaseapp.com",
  projectId: "capitus-daf71",
  storageBucket: "capitus-daf71.firebasestorage.app",
  messagingSenderId: "689952037031",
  appId: "1:689952037031:web:58b15cc3203b6c2a946382",
  measurementId: "G-7B1FDWJVDJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <Router>
    <ScrollToTop /> {/* Adicionado aqui */}
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/creditos" element={<SimularCredito />} />
        <Route path="/solucoes" element={<SolucoesFinanceiras />} />
        <Route path="/seguros" element={<Seguros />} />
        <Route path="/parapf" element={<ParaMim />} />
        <Route path="/limpa-nome" element={<LimpaNome />} />
        <Route path="/seguro-residencial" element={<SeguroResidencial />} />
        <Route path="/parapj" element={<ParaEmpresa />} />
        <Route path="/quem-somos" element={<Sobre />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/politica-de-privacidade" element={<PoliticaPrivacidade />} />
        <Route path="/termos-de-uso" element={<TermoDeUso />} />

      </Routes>
      {/* <WhatsAppButton /> */}

      <Footer />
      <BrevoConversations/>
    </Router>
  );
};

export default App;
