import React from 'react';
import Header from "../components/Header"; // Importação do Header
import Footer from "../components/Footer"; // Importação do Footer
import WhatsAppButton from './WhatsAppButton';
import './CotacaoFacilPage.css';
import './WhatsAppButton.css';

const CotacaoFacilPage = () => {
  return (
    <div className="cotacao-page">


      <Header /> {/* Renderizando o Header */}
      <h4>APÓS PREENCHER O FORMULÁRIO, ENTRE EM CONTATO PARA FINALIZAR O PROCESSO.</h4>
      <iframe
        src="https://cotacaofacil.aggilizador.com.br"
        width="100%"
        // height="100%"
        title="Cotação Fácil"
      ></iframe>
      <WhatsAppButton />
      
    </div>
  );
};

export default CotacaoFacilPage;