import React from "react";
import bg from "../assets/bg.jpg"; // Imagem de fundo
import "./Home.css"; // Estilos atualizados
import Header from "../components/Header";
import LogoParceiros from "../components/LogoParceiros";

const OutroComponente = () => {
  return (
    <div id="contato" className="contact-section">
      <h2>Fale Conosco</h2>
      <p>
        Conheça nossas soluções financeiras personalizadas. Estamos prontos para
        ajudar você e sua empresa a alcançar o sucesso.
      </p>
      <button className="action-button">Entre em Contato</button>
    </div>
  );
};

const Home = () => {
  const cards = [
    {
      title: "Empréstimos",
      description: "Condições exclusivas para você.",
      img: require("../assets/emprestimos2.jpg"),
      link: "/contato",
    },
    {
      title: "Financiamentos",
      description: "Viabilize seus sonhos e projetos.",
      img: require("../assets/financiamentos.jpg"),
      link: "/contato",
    },
    {
      title: "Seguros",
      description: "Proteção para você e sua empresa.",
      img: require("../assets/seguros.jpg"),
      link: "/seguros",
    },
    {
      title: "Planos de Saúde",
      description: "Cuide de quem importa para você.",
      img: require("../assets/planosdesaude2.jpg"),
      link: "/contato",
    },
  ];

  return (
    <>
      {/* Renderização do Componente Header */}
      <Header />

      {/* Header Principal */}
      <header
        id="home"
        className="home-container"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="overlay"></div> {/* Overlay escuro */}
        <div className="home-content">
          <h1>Bem-vindo à Capitus:</h1>
          <p>Soluções que acompanham você e sua empresa em todos os momentos.</p>
          <a href="solucoes" className="action-button">
            Conheça Nossas Soluções
          </a>
        </div>

        {/* Grid de Cards sobre o fundo */}
        <section id="cards-section" className="cards-grid">
          {cards.map((card, index) => (
            <div key={index} className="card">
              <img src={card.img} alt={card.title} className="card-image" />
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
              <a href={card.link} className="action-button">
                Saiba Mais
              </a>
            </div>
          ))}
        </section>
      </header>

      {/* Seção de Parceiros e Contato */}
      <LogoParceiros />
      <OutroComponente />
    </>
  );
};

export default Home;
